import _ from 'lodash';
import React from 'react';
import styled from '@emotion/styled';
import Layout from '../components/Layout';
import Head from '../components/Head';
import Header from '../components/Header';
import MapBox from '../components/Map';

const MapContainer = styled.div`
  width: 100vw;
  height: 88vh;
`;

const schema = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  inLanguage: 'en',
  name: 'Wine Bar and Shop Map in London',
  url: 'https://seventyfive.cl/map',
  description: "Map of London's best wine bars and shops",
  alternateName: ['75cl'],
  keywords: ['map', 'wine', 'wine shops', 'wine bars', 'london'],
  sameAs: ['https://www.instagram.com/seventyfivecl'],
};

export default ({ pageContext }) => {
  const {
    citiesList,
    defaultCity,
    token,
    style,
    wineBars,
    stores,
  } = pageContext;

  return (
    <Layout>
      <Head
        title={schema.name}
        description={schema.description}
        schema={schema}
      />
      <Header citiesList={citiesList} defaultCity={defaultCity} />
      <MapContainer>
        <MapBox
          latitude={51.5202}
          longitude={-0.1048}
          token={token}
          style={style}
          zoom={13}
          markers={[...wineBars, ...stores]}
        />
      </MapContainer>
    </Layout>
  );
};
